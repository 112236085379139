import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyACAM_mb5hzOZVt3BfGSaHVGTCR1XV8Qik",
    authDomain: "blackwidow-cms-demo.firebaseapp.com",
    projectId: "blackwidow-cms-demo",
    storageBucket: "blackwidow-cms-demo.appspot.com",
    messagingSenderId: "365408920007",
    appId: "1:365408920007:web:7d91725e32354a5d8c39e2"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { messaging };
